import { verifyAdminUser } from '@/services/user.service'
import { User } from '@auth0/auth0-spa-js'
import { useAuth0 } from '@auth0/auth0-vue'
import { AxiosError } from 'axios'
import { createStore } from 'vuex'

type State = {
  loading: boolean
  error: any
  token: string | null
  viewer: User | null
  version: string | null
  lastModified: Date | null
  pageTitle: string | null
}
const TOKEN_KEY = 'US_HMS_ADMIN_TOKEN'

export default createStore({
  state: {
    loading: false,
    error: null,
    token: localStorage.getItem(TOKEN_KEY) ?? null,
    viewer: null,
    version: null,
    lastModified: null,
    pageTitle: null,
  },

  getters: {
    isAuthenticated: (state: State) => !!state.viewer,
    viewer: (state: State) => state.viewer,
    error: (state: State) => state.error,
  },

  mutations: {
    loading (state: State, value: boolean) {
      state.loading = value
    },

    error (state: State, value: any) {
      state.error = value
    },

    token (state: State, token: string | null) {
      if (token) {
        localStorage.setItem(TOKEN_KEY, token)
      } else {
        localStorage.removeItem(TOKEN_KEY)
      }
      state.token = token
    },

    viewer (state: State, viewer: User) {
      state.viewer = viewer
    },

    version (state: State, { version, lastModified }) {
      state.version = version
      state.lastModified = lastModified
    },

    pageTitle (state: State, title: string) {
      state.pageTitle = title
    },
  },

  actions: {
    async signIn ({ commit }: { commit: any }, user: User) {
      commit('loading', true)

      const { getAccessTokenSilently } = useAuth0()
      const token = await getAccessTokenSilently()

      if (token) {
        commit('token', token)
        commit('viewer', user)
        try {
          await verifyAdminUser()
        } catch (e) {
          if (e instanceof AxiosError && e.response) {
            if (typeof e.response.data.detail === 'string') {
              commit('error', e.response.data.detail)
            } else {
              (e.response.data.detail ?? []).forEach((detail:any) => {
                commit('error', `${detail.msg}: ${detail.loc[detail.loc.length-1]}`)
              })
            }
          }
        }
      }

      commit('loading', false)
    },

    async signOut ({ commit }: { commit: any }) {
      commit('token', null)
      commit('viewer', null)
      const url = `https://${process.env.VUE_APP_SMART_PHR_DOMAIN}/v2/logout`
      const query = `returnTo=${window.location.origin}&client_id=${process.env.VUE_APP_SMART_PHR_CLIENT_ID}`
      window.location.replace(`${url}?${query}`)
    },

    async version ({ state, commit }: { state: any; commit: any }, toPath: (string | Location) & Location) {
      const options: any = { credentials: 'same-origin' }
      if (state.lastModified) options.headers = { 'If-Modified-Since': state.lastModified }

      const response = await fetch(`/version?t=${new Date().toISOString()}`, options)
      const responseText = await response.text()

      if (!state.version) {
        const lastVersion = localStorage.getItem('version')
        localStorage.setItem('version', responseText)

        if (lastVersion && lastVersion !== responseText) window.location = toPath

        commit('version', { version: responseText, lastModified: response.headers.get('last-modified') })
      } else if (response.status === 200) {
        if (responseText && responseText !== state.version) window.location = toPath
      }
    },
  },

  modules: {},
})
